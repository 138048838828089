import React, { useState } from 'react'
import styled from 'styled-components'
// import { useCart, useCartItems, useCartCount } from 'gatsby-theme-shopify-manager'

import { breakpoints, dimensions, GlobalStyle, theme } from '../styles/globalStyle'
import { useGlobalContext } from '../context/globalContext'
import { useStoreContext } from '../context/storeContext'
import { Button, CartItem, Container, Layout } from '../components'
import { formatPrice } from '../utils'

export default function Cart() {
  const { cartOpen, closeCart } = useGlobalContext()
  const { checkout, loading } = useStoreContext()
  const emptyCart = checkout.lineItems.length === 0
  // const checkout = useCart()
  // const cartCount = useCartCount()
  // const cartItems = useCartItems()

  // const [loading, setLoading] = useState(false)

  // const emptyCart = cartCount === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  return (
    <Layout>
      <Container>
        <GlobalStyle />
        <CartWrap className={cartOpen && 'opened'}>
          {/* <CloseCart /> */}
          {emptyCart ? (
            <CartEmpty>
              <h2>Your Cart is Empty</h2>
              <p>Visit our catalog to fill it up</p>
              <div onClick={closeCart}>
                <Button title="Catalog" url="/shop" buttonColor={theme.colors.secondary} />
              </div>
            </CartEmpty>
          ) : (
            <CartInner>
              <CartItemsWrap>
                {checkout.lineItems.map(item => (
                  /* {cartItems.map(item => ( */
                  <CartItem item={item} key={item.id} />
                ))}
              </CartItemsWrap>
              <CheckoutInfo>
                <p>
                  <span>Tax:</span> {formatPrice(checkout.totalTaxV2.currencyCode, checkout.totalTaxV2.amount)}
                </p>
                <p>
                  <span>Shipping:</span> Calculated at checkout
                </p>
                <p>
                  <span>Total:</span> {formatPrice(checkout.totalPriceV2.currencyCode, checkout.totalPriceV2.amount)}
                </p>
              </CheckoutInfo>
              <CheckoutBtn onClick={handleCheckout} disabled={loading} className="generic-button">
                Proceed to Checkout {formatPrice(checkout.totalPriceV2.currencyCode, checkout.totalPriceV2.amount)}
              </CheckoutBtn>
            </CartInner>
          )}
        </CartWrap>
      </Container>
    </Layout>
  )
}

const CartWrap = styled.div`
  transition: ${theme.misc.transitionEase};
  padding: 2rem 1rem 1rem;
  overflow: auto;
`
const CartEmpty = styled.div`
  text-align: center;
`
const CartInner = styled.div`
  display: grid;
  gap: 2rem;
  position: relative;
  min-height: 100%;
  padding-bottom: 4rem;
`

const CheckoutInfo = styled.div`
  text-align: right;

  p {
    color: ${theme.colors.secondary};
    margin-bottom: 0.5rem;
    font-weight: ${theme.weights.bold};

    span {
      font-weight: ${theme.weights.normal};
    }
  }
`

const CartItemsWrap = styled.div`
  display: grid;
  gap: 1.25rem;
`
const CheckoutBtn = styled.button`
  position: absolute;
  padding: 0.75rem 0;
  bottom: 0;
  left: 0;
  width: 100%;

  &:hover {
    background: ${theme.colors.secondary};
    color: ${theme.colors.white};
  }
`
